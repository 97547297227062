.about-item{
	i{
		font-size: 50px;
	}
	a{
		color: $primary-color;
		font-weight: 500;
	}
	.icon{
		float: left;
		height: unset;
		width: unset;
	}

	.content{
		padding-left:80px;
	}

}



//  Process 
.process{
	position: relative;

	&:before{
		position: absolute;
		content:"";
		right: 0px;
		top:0px;
		width: 100%;
		height: 100%;
		background: url("../images/bg/bg-half.png")no-repeat;
		background-position: top right;
	}

}



.icon-block{
	padding:50px 40px;
	background: $light;
	border-radius: 10px;
	box-shadow: 0px 0px 40px 0px rgba(85, 128, 255, 0.2);

	i{
		font-size: 35px;
		background: rgba(239, 50, 58,.08);
		width: 70px;
		height: 70px;
		text-align: center;
		padding-top: 16px;
		border-radius: 10px;
		display: inline-block;
		color: $primary-color;
	}

	h5{
		margin-top: 25px;
		margin-bottom: 15px;
	}
	p{
		margin-bottom: 0px;
	}
}


// About Pages


.feature-list{
	ul li {
		margin-bottom: 15px;
		color: $black;

		i{
			color: $primary-color;
		}
	}
}


.video-img{
	position: relative;
	.video-play{
		position: absolute;
		content:"";
		left: 0px;
		right:0px;
		top:50%;
		width: 100%;
		height: 100%;
		margin:0 auto;
		margin-top: -50px;
		text-align: center;
		
		i{
			font-size: 30px;
			width: 100px;
			height: 100px;
			background: $light;
			color: $primary-color;
			border-radius:100%;
			text-align: center;
			display: inline-block;
			line-height: 100px;
			box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.04);
		}
	}
}


// Awards

.award-img-block{
	height: 160px;
	border:1px solid #eee;
	align-items: center;
	display: flex;
	justify-content: center;
	background: $light;
}


//  Video
//  

.video-play:hover{
	cursor: pointer;
}

.mt-30 {
	margin-top: 30px;
}

@include tablet {
	.custom-grid {
		.mt-30 {
			margin-top: 0 !important;
		}
		.pb-0 {
			padding-bottom: 15px !important;
		}
		.pt-0 {
			padding-top: 15px !important;
		}
	}
}