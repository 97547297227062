.cta{
	background: url("../images/bg/bg-2.jpg")no-repeat;
	background-size: cover;
	position: relative;
}

.cta-content {
	padding: 40px;
	@include mobile {
		padding: 40px 25px;
	}
}

.mb-30{
	margin-bottom: 30px;
}

.text-color-primary{
	color: $primary-color;
}