$light: #fff;
$primary-color: #FF715B;
$secondary-color:#FF715B;
// $base-color:#62618d;
$base-color:#6F8BA4;
$black:#111;
// $black:#031043;
$border-color:#fafafa;
$primary-font: 'Rubik', sans-serif;
$secondary-font:'Montserrat', sans-serif;

$grad-color:linear-gradient(to bottom,#05a5f9,#10dab7);