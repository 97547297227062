.testimonial-wrap {
	margin-left: calc((100% - 1140px)/2);
	margin-right: -10%;
	margin-top: -30px;
}

.testimonial-block {
	margin: 0px 10px;
	padding: 30px 0px;
	margin-left: 12px;
	padding-left: 15px;
	padding-right: 0px;

	p {
		background: $light;
		box-shadow: 0px 0px 25px 0px rgba(85, 128, 255, 0.2);
		padding: 60px 40px;
		position: relative;
		margin-bottom: 30px;
		font-size: 18px;

		&:after {
			position: absolute;
			content: "";
			left: 20px;
			bottom: -20px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 25px 30px 0 0;
			border-color: $light transparent transparent;
		}

	}

	.client-info {
		.client-img {
			float: left;
			margin-right: 30px;
			margin-bottom: 20px;

			img {
				border-radius: 100%;
			}
		}

		.info {
			h6 {
				margin-bottom: 0px;
			}
		}
	}
}


@include mobile {
	.testimonial-wrap {
		margin-left: 0px;
	}
}

@include mobile-xs {
	.testimonial-wrap {
		margin-left: 0px;
	}
}

@include tablet {
	.testimonial-wrap {
		margin-left: 0px;
	}
}

@include desktop {
	.testimonial-wrap {
		margin-left: 0px;
	}
}
