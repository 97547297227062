.pricing{
	// background: url("../images/bg/bg-4.jpg") no-repeat;
	// background-size: cover;
	// position: relative;

	// &:before{
	// 	position: absolute;
	// 	content:"";
	// 	left: 0px;
	// 	top:0px;
	// 	width: 100%;
	// 	height: 100%;
	// 	background: rgba(239, 50, 58, 0.8);
	// }
}

.pricing-block{
	padding: 20px 0px 50px 0px;
	border-top:7px solid $secondary-color;
	border-radius: 10px;
	box-shadow: 0 0 15px rgba(0,0,0,.1);
	border:0px;


	.price-header{
		padding: 30px 0px;

		h2{
			font-weight: 700;
			margin: 15px 0px;
		}
	}

	.price-body{
		padding: 30px 0px;
		border-top:2px solid rgba(0,0,0,0.06);
		border-bottom:2px solid rgba(0,0,0,0.06);
		margin-bottom: 30px;

		ul li{
			padding: 10px 0px;
		}
	}

	.price-footer{
		margin-bottom: 30px;
	}
}



.pricing-block.featured{
	// background: rgba(239, 50, 58, 0.08);
	.price-header{
		h2,h6,span{
			// color: $light;
		}
	}

	.price-body{
		li{
			// color: rgba(255,255,255,.8);
		}
	}
}





.text-underline{
	text-decoration: underline;
}