.banner {
   position: relative;
   overflow: hidden;
   background: $light;
   background: url("../images/bg/main-banner1.jpg") no-repeat 80% 50%;
   background-size: cover;
   padding: 120px 0px;
   @include mobile {
      padding: 80px 0px;
   }

   .block {
      padding-top: 120px;
      @include mobile {
         padding-top: 100px;
      }

      h1 {
         font-size: 75px;
         line-height: 1.2;
         font-weight: 400;
         letter-spacing: -1.2px;
         text-transform: capitalize;
      }
   }
}

.letter-spacing {
   letter-spacing: 2px;
}

.text-color {
   color: $secondary-color;
}

@include large-desktop {
   .banner {
      background: $light !important;
   }
}

@include desktop {
   .banner {
      background: $light !important;
   }
}

@include tablet {
   .banner .block h1 {
      font-size: 56px;
      line-height: 70px;
   }

   .banner {
      background: $light !important;
   }
}

@include mobile {
   .banner .block h1 {
      font-size: 38px;
      line-height: 50px;
   }

   .banner {
      background: $light !important;
   }
}

@include mobile-xs {
   .banner .block h1 {
      font-size: 28px;
      line-height: 40px;
   }

   .banner {
      background: $light !important;
   }

}