.contact-form-wrap{
      .form-group{
        padding-bottom: 15px;
        margin: 0px;
        .form-control{
          height: 48px;
          border: 1px solid #EEF2F6;
          box-shadow: none;
          width: 100%;
        }
      }
      .form-group-2{
        margin-bottom: 13px;
        textarea{
          height: 135px;
          border: 1px solid #EEF2F6;
          box-shadow: none;
          width: 100%;
        }
      }
}


.address-block {
  li {
    margin-bottom:15px;
    i {
      font-size: 20px;
      width: 20px;
    }
  }
}


.short-info{
  li{
    margin-bottom: 25px;
  }
}

.social-icons {
  li {
    margin:0 6px;
  }
 
  a{
    margin-right: 10px;
    font-size: 18px;
  }
}

.google-map {
    position: relative;
}

.google-map #map {
    width: 100%;
    height: 450px;
}


.short-info{
  padding: 40px;
  border:1px solid #eee;
}


.mt-90{
  margin-top: 90px;
}