.feature{
	background: $grad-color;
}


.feature-block{
	background: $light;
	padding: 30px;
	text-align: center;

	i{
		font-size: 40px;
	}
	h5{
		margin-top: 20px;
	}
}

.text-lg{
	font-size:50px;
}